import angular from 'angular';
import _ from 'lodash';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($window, $timeout, $document) {
    this.localCss = localCss;
    this.$window = $window;
    this.direction = 'bottom';
    this.visible = false;
    this.handleNgAffixTopEvent = event => {
      $timeout(() => {
        if (!(event.detail && event.detail.fixedTop)) {
          this.direction = 'bottom';
        }
      });
    };

    this.handleScrollEvent = _.debounce(
      () => {
        if ($document.scrollTop() === 0) {
          if (this.direction !== 'bottom') {
            $timeout(() => {
              this.direction = 'bottom';
            });
          }
        } else if ($document.scrollTop() + $window.innerHeight >= $document.height() / 2) {
          if (this.direction !== 'top') {
            $timeout(() => {
              this.direction = 'top';
            });
          }
        } else if (this.direction !== 'bottom') {
          $timeout(() => {
            this.direction = 'bottom';
          });
        }
      },
      60,
      { maxWait: 180 },
    );

    this.handleLoadEvent = () => {
      $timeout(this.updateVisibility.bind(this), 250);
    };

    this.handleResize = _.debounce(() => {
      $timeout(this.updateVisibility.bind(this));
    }, 60);
  }

  updateVisibility() {
    const bottomBarElem = angular.element('.bottom-bar')[0];
    if (bottomBarElem) {
      const bottomBarPosition = angular.element(this.$window).scrollTop() + bottomBarElem.getBoundingClientRect().top;
      this.visible = bottomBarPosition - this.$window.innerHeight > 0;
    } else {
      this.visible = false;
    }
  }

  $onInit() {
    if (angular.isUndefined(this.animate)) {
      this.animate = true;
    }
    this.$window.addEventListener('load', this.handleLoadEvent); // onInit is triggered before page load
    this.$window.addEventListener('ngAffixTop', this.handleNgAffixTopEvent);
    this.$window.addEventListener('scroll', this.handleScrollEvent);
    this.$window.addEventListener('resize', this.handleResize);
  }

  moveUp() {
    if (this.animate) {
      angular.element('html, body').animate({ scrollTop: 0 }, 300);
    } else {
      this.$window.scrollTo(0, 0);
    }
  }

  moveDown() {
    const bottomBarPosition = angular.element(this.$window).scrollTop() + angular.element('.bottom-bar')[0].getBoundingClientRect().top;
    if (this.animate) {
      angular.element('html, body').animate({ scrollTop: bottomBarPosition - this.$window.innerHeight }, 300);
    } else {
      this.$window.scrollTo(0, bottomBarPosition - this.$window.innerHeight);
    }
  }

  move() {
    if (this.direction === 'bottom') {
      this.moveDown();
    } else {
      this.moveUp();
    }
  }

  $onDestroy() {
    this.$window.removeEventListener('resize', this.handleResize);
    this.$window.removeEventListener('scroll', this.handleScrollEvent);
    this.$window.removeEventListener('ngAffixTop', this.handleNgAffixTopEvent);
    this.$window.removeEventListener('load', this.handleLoadEvent);
  }
}

const BackToTopButton = {
  template,
  controller: ['$window', '$timeout', '$document', Ctrl],
  bindings: {
    animate: '<?',
  },
};

angular.module('artprice_app').component('artpSearchBackToTopButton', BackToTopButton);
